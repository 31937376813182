$headerBgColor: #1e1e24;
$secondaryBgColor: #fb8500;
$homeBgColor: #21222b;
$whiteBgColor: #1a0044;
$contactPageBg: #0a001b;
$headlineTextColor: #e9e9e9;

$purpleBtnColor: #811ae5;

$globalFontSize: 1.1rem;
$primaryTextColor: #fff;
$secondaryTextColor: #ffffff;

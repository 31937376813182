@import "../../Styles/variables";

.home-container {
  width: 100%;
  height: calc(100vh - 70px);
  background: $homeBgColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 70px;
  bottom: 0;
}

.home-container__headline {
  width: 60%;
  height: auto;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__buttons {
    display: flex;
    gap: 20px;
    &__my-work {
      border: 2px solid $secondaryBgColor;
      color: #fb8500;
      display: flex;
      gap: 10px;
      align-items: center;
      &:hover {
        background: $secondaryBgColor;
        border: 2px solid $secondaryBgColor;
        color: #fff;
      }
    }
    &__github {
      background: #811ae5;
      border: 2px solid transparent;
      display: flex;
      gap: 10px;
      align-items: center;
      &:hover {
        background: transparent;
        border: 2px solid #811ae5;
        color: #fff;
      }
    }
  }
  &__text {
    color: $primaryTextColor;
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
    user-select: none;
  }
}

// X-Small devices (portrait phones, less than 576px)

// Small devices (landscape phones, 576px and below)
@media (max-width: 576px) {
  .home-container {
    &__headline {
      &__text {
        font-size: 2rem;
      }
    }
  }
}

// Medium devices (tablets, 768px and below)
@media (max-width: 768px) {
  .home-container {
    &__headline {
      width: 100%;
    }
  }
}

// Large devices (desktops, 992px and below)
@media (max-width: 992px) {
}

// X-Large devices (large desktops, 1200px and below)
@media (max-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and below)
@media (max-width: 1400px) {
}

.contact-container {
  background: $contactPageBg;
  position: relative;
  z-index: 98;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  &__headline {
    @include site-headline;
    margin-top: 100px;
    &::after {
      border-top: 10px solid #0a001b;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 40%;
    margin: auto;
    font-family: "Raleway", sans-serif;
    position: relative;
    &__sent {
      position: absolute;
      top: 0;
      background: $contactPageBg;
      color: $primaryTextColor;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    &__input {
      padding: 15px;
      background: $whiteBgColor;
      color: $secondaryTextColor;
      outline: none;
      border: 0;
      transition: filter 0.3s;
      &:focus {
        filter: drop-shadow(0 0 0.75rem rgb(62, 15, 173));
      }
      &::placeholder {
        font-family: "Raleway", sans-serif;
        color: $secondaryTextColor;
      }
    }
    &__message {
      background: $whiteBgColor;
      color: $secondaryTextColor;
      outline: none;
      border: 0;
      padding: 15px;
      transition: filter 0.3s;
      &:focus {
        filter: drop-shadow(0 0 0.75rem rgb(62, 15, 173));
      }
      &::placeholder {
        font-family: "Raleway", sans-serif;
        color: $secondaryTextColor;
      }
    }
    &__button {
      width: 200px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      gap: 10px;
      align-self: center;
      background: transparent;
      border-width: 4px;
      border-style: solid;
      border-image: linear-gradient(to left, #743ad5, #d53a9d) 1;
      transition: background 0.3s;
      &:hover {
        background: linear-gradient(to left, #743ad5, #d53a9d);
      }
    }
  }
  &__contact-details {
    color: $secondaryTextColor;
    width: 40%;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;
    &__info {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 7px;
    }
    &__socials {
      display: flex;
      gap: 7px;
      margin-top: 10px;
      &__icon {
        align-self: center;
        cursor: pointer;
        background: linear-gradient(
          180deg,
          rgba(251, 249, 152, 0.705) 0%,
          rgba(251, 133, 0, 1) 100%
        );
        background-size: 400% 400%;
        height: 30px;
        padding: 10px;
        border-radius: 180px;
        animation: btnGradient 2s linear infinite;
        transition: filter 0.3s;
        &:hover {
          filter: drop-shadow(0 0 0.75rem rgb(62, 15, 173));
          & svg {
            scale: 1.3;
          }
        }
      }
    }
  }
}

.custom-shape-divider-top-1695729457 {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1695729457 svg {
  position: relative;
  display: block;
  width: calc(127% + 1.3px);
  height: 143px;
}

.custom-shape-divider-top-1695729457 .shape-fill {
  fill: #1a0044;
}

// X-Small devices (portrait phones, less than 576px)

// Small devices (landscape phones, 576px and below)
@media (max-width: 576px) {
}

// Medium devices (tablets, 768px and below)
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    &__form {
      order: 2;
      width: 80%;
      margin-top: 20px;
    }
    &__contact-details {
      width: 80%;
      align-items: center;
    }
  }
}

// Large devices (desktops, 992px and below)
@media (max-width: 992px) {
}

// X-Large devices (large desktops, 1200px and below)
@media (max-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and below)
@media (max-width: 1400px) {
}

@import "../../Styles/variables";
@import "../../Styles/mixins";

.projects-container {
  background: $whiteBgColor;
  z-index: 10;
  position: relative;
  overflow: hidden;
  &__headline {
    @include site-headline;
    z-index: 12;
  }
  &__type-selector {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    align-self: flex-start;
    z-index: 12;
    &__button {
      color: $secondaryTextColor;
      margin-top: 0;
      transition: background 0.3s;
      &:hover {
        background: $secondaryBgColor;
      }
    }
  }
  &__projects {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
  }
}
.projects-container {
  &__tile {
    flex: 1 1;
    min-width: 350px;
    max-width: 500px;
    aspect-ratio: 1/1;
    position: relative;
    display: flex;
    z-index: 12;
    &__overlay {
      // That's the whole new tile that comes up on hover
      width: 100%;
      height: 100%;
      background: $whiteBgColor;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      opacity: 0;
      transition: opacity 0.5s;
      display: grid;
      grid-template-rows: 1fr 2fr 1fr;
      justify-items: center;
      &:hover {
        opacity: 90%;
      }
      &__thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0;
      }
      &__title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primaryTextColor;
        @include underline;
      }
      &__desc {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        color: $primaryTextColor;
        margin: 0 10px 0 10px;
        line-height: 25px;
      }
      &__button {
        border: 2px solid $secondaryBgColor;
        color: $secondaryTextColor;
        margin-right: 5px;
        &:hover {
          background: $secondaryBgColor;
          color: $primaryTextColor;
        }
        &--purple {
          background: $purpleBtnColor;
          border: 2px solid $purpleBtnColor;
          &:hover {
            background: transparent;
            border: 2px solid $purpleBtnColor;
            color: $secondaryTextColor;
          }
        }
      }
    }
  }
}

// Background blobs and animation

.shape-blob {
  --blob__height: 30vw;
  background: #c812ed;
  height: var(--blob__height);
  width: var(--blob__height);
  border-radius: 30% 50% 20% 40%;
  animation: transform 30s ease-in-out infinite both alternate,
    movement_two 30s ease-in-out infinite both;
  opacity: 0.3;
  position: absolute;

  z-index: 11;
  &.one {
    left: -7vw;
    top: -30px;
    transform: rotate(-180deg);
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      opacity: 100%;
    }
  }

  &.two {
    z-index: 11;
    height: calc(var(--blob__height) + 16vh);
    width: calc(var(--blob__height) + 16vh);
    right: -20vw;
    top: 80px;
    transform: rotate(-180deg);
    animation: transform 30s ease-in-out infinite both alternate,
      movement_one 40s ease-in-out infinite both;
    z-index: 12;
  }
}

@import "../../Styles/variables";
@import "../../Styles/mixins";

.about-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  z-index: 98;
  background: $whiteBgColor;
  padding-bottom: 200px;
  padding-top: 200px;
  &__headline {
    @include site-headline;
    margin-top: 0;
  }
  &__my-image-wrapper {
    background: #000;
    background: -moz-linear-gradient(
      -45deg,
      #000000 0%,
      #000000 25%,
      #1e539e 50%,
      #ff3083 75%,
      #7800a8 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      #000000 0%,
      #000000 25%,
      #1e539e 50%,
      #ff3083 75%,
      #7800a8 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      #000000 0%,
      #000000 25%,
      #1e539e 50%,
      #ff3083 75%,
      #7800a8 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-size: 400% 400%;
    background-repeat: no-repeat;
    position: relative;
    transition: 0.5s background;
    width: 40%;
    border-radius: 0;
    max-height: 700px;
    min-height: 500px;
    animation: blob 10s ease-in-out infinite;
    overflow: hidden;
    &__image {
      width: 100%;
      height: 100%;
      background: #000 url("/src/images/myself.webp") no-repeat center
        center;
      position: absolute;
      left: 0;
      top: 0;
      background-size: cover;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.5;
      mix-blend-mode: screen;
    }
    &:hover {
      background-position: 100% 100%;
    }
  }

  &__data {
    width: 40%;
    font-size: 1.2rem;
    &__desc {
      color: $primaryTextColor;
      line-height: 30px;
      text-indent: 2rem;
      font-size: 1rem;
    }
  }
  &__icons {
    color: $secondaryTextColor;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    margin-top: 30px;
    background: rgb(138, 46, 180);
    background: radial-gradient(
      circle,
      rgba(138, 46, 180, 0.6082808123249299) 0%,
      rgba(26, 0, 68, 1) 79%
    );
    &__headline {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: center;
    }
    &__icon {
      padding: 15px;
      background: #fff;
      height: auto;
      width: auto;
      background: #100029;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      transition: filter 0.3s ease; /* Apply transition only to filter on hover */
      user-select: none;
      &:hover {
        filter: drop-shadow(0 0 0.75rem rgb(152, 117, 235));
      }
      p {
        font-size: 1rem;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)

// Small devices (landscape phones, 576px and below)
@media (max-width: 576px) {
}

// Medium devices (tablets, 768px and below)
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
    &__data {
      width: 80% !important;
    }
    &__my-image-wrapper {
      width: 80% !important;
    }
    &__headline {
      order: 1;
    }
  }
}

// Large devices (desktops, 992px and below)
@media (max-width: 992px) {
  .about-container {
    &__data {
      width: 48%;
    }
    &__my-image-wrapper {
      width: 48%;
    }
  }
}

// X-Large devices (large desktops, 1200px and below)
@media (max-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and below)
@media (max-width: 1400px) {
}

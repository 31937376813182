.project-detail-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: $headerBgColor;
  color: $primaryTextColor;
  display: flex;
  z-index: 96;
  &__overlay {
    width: 100%;
    height: 100%;
    background: $whiteBgColor;
    position: fixed;
    overflow: hidden;
    z-index: 97;
  }
  &__image {
    width: 50%;
    height: auto;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &__thumbnail {
      width: 100%;
      min-height: 100%;
      min-width: 630px;
    }
  }
  &__data {
    width: 40%;
    padding: 20px 20px 100px 20px;
    margin-top: 30px;
    line-height: 28px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &__tags {
      color: #933ee7;
      font-size: 1.3rem;
      margin-top: 15px;
      font-weight: bold;
      font-style: italic;
    }
    &__desc {
      margin-top: 20px;
      white-space: pre-wrap;
      & ul {
        line-height: 1rem;
        & li {
          margin-left: 1rem;
        }
      }
    }
    &__back-button {
      color: $primaryTextColor;
      transition: all 0.5s;
      &:hover {
        color: $secondaryBgColor;
        cursor: pointer;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)

// Small devices (landscape phones, 576px and below)
@media (max-width: 600px) {
  .project-detail-container {
    &__image {
      &__thumbnail {
        min-width: 400px;
      }
    }
  }
}

// Medium devices (tablets, 768px and below)
@media (max-width: 768px) {
  .project-detail-container {
    overflow: scroll;
    flex-wrap: wrap;
    &__image {
      order: 1;
      width: 100%;
      margin-top: 50px;
    }
    &__data {
      width: 100%;
    }
  }
}
